<!-- 视频监控 -->
<template>
  <div class="video">
    <div id="video-container1"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, onBeforeUnmount, getCurrentInstance } from 'vue'
import { useRoute } from "vue-router";
import EZUIKit from "ezuikit-js";
const props = defineProps(['othersId', 'accessToken'])
var ezvizPlayOne = ref(null);
const { proxy } = getCurrentInstance();
const route = useRoute();
const state = {
  id: '',
  othersId: '',
  accessToken: ''
}
state.id = route.query.id;
const autoVideoOne = async () => {
  let { data } = await proxy.$api.system.getElevatorDetail(state.id);
  let item = await proxy.$api.common.getAccToken();
  state.accessToken = item.data.data.accessToken
  state.othersId = data.othersId
  console.log(data, "data");
  // 获取父节点的宽高
  if (ezvizPlayOne.value !== null) {
    return;
  }
  //获取萤石token
  ezvizPlayOne.value = new EZUIKit.EZUIKitPlayer({
    // autoplay: true,  // 默认播放
    //视频播放包裹元素
    id: "video-container1",
    //萤石token
    accessToken: state.accessToken,
    // ezopen://open.ys7.com/${设备序列号}/{通道号}.live
    url: state.othersId, // 播放地址
    template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
    plugin: ['talk'], // 加载插件，talk-对讲
    // 视频下方底部控件
    footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
    width: 400,
    height: 500
  })

}
const destroyEl = () => {
  ezvizPlayOne.value.play();
  setTimeout(() => {
    ezvizPlayOne.value.Talk.stopTalk()
  }, 500)
}
onMounted(() => {
  autoVideoOne();
})
onBeforeUnmount(() => {
  destroyEl()
})

</script>
<style lang="scss" scoped>
.video {
  width: 100%;
  height: 80%;
  margin: 0 auto;
}
</style>
